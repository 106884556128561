import { e, b, g, c, f, a, h } from './chunk-I2QGXAA3.js';
import { twMerge, extendTailwindMerge } from 'tailwind-merge';
var ie = {
    twMerge: !0,
    twMergeConfig: {},
    responsiveVariants: !1
  },
  x = s => s || void 0,
  N = (...s) => x(e(s).filter(Boolean).join(" ")),
  R = null,
  v = {},
  q = !1,
  M = (...s) => b$1 => b$1.twMerge ? ((!R || q) && (q = !1, R = b(v) ? twMerge : extendTailwindMerge({
    ...v,
    extend: {
      theme: v.theme,
      classGroups: v.classGroups,
      conflictingClassGroupModifiers: v.conflictingClassGroupModifiers,
      conflictingClassGroups: v.conflictingClassGroups,
      ...v.extend
    }
  })), x(R(N(s)))) : N(s),
  _ = (s, b) => {
    for (let e in b) s.hasOwnProperty(e) ? s[e] = N(s[e], b[e]) : s[e] = b[e];
    return s;
  },
  ce = (s, b$1) => {
    let {
        extend: e = null,
        slots: O = {},
        variants: U = {},
        compoundVariants: W = [],
        compoundSlots: C = [],
        defaultVariants: z = {}
      } = s,
      m = {
        ...ie,
        ...b$1
      },
      k = e != null && e.base ? N(e.base, s == null ? void 0 : s.base) : s == null ? void 0 : s.base,
      g$1 = e != null && e.variants && !b(e.variants) ? g(U, e.variants) : U,
      w = e != null && e.defaultVariants && !b(e.defaultVariants) ? {
        ...e.defaultVariants,
        ...z
      } : z;
    !b(m.twMergeConfig) && !c(m.twMergeConfig, v) && (q = !0, v = m.twMergeConfig);
    let S = b(e == null ? void 0 : e.slots),
      T = b(O) ? {} : {
        base: N(s == null ? void 0 : s.base, S && (e == null ? void 0 : e.base)),
        ...O
      },
      j = S ? T : _({
        ...(e == null ? void 0 : e.slots)
      }, b(T) ? {
        base: s == null ? void 0 : s.base
      } : T),
      h$1 = b(e == null ? void 0 : e.compoundVariants) ? W : f(e == null ? void 0 : e.compoundVariants, W),
      V = l => {
        if (b(g$1) && b(O) && S) return M(k, l == null ? void 0 : l.class, l == null ? void 0 : l.className)(m);
        if (h$1 && !Array.isArray(h$1)) throw new TypeError(`The "compoundVariants" prop must be an array. Received: ${typeof h$1}`);
        if (C && !Array.isArray(C)) throw new TypeError(`The "compoundSlots" prop must be an array. Received: ${typeof C}`);
        let P = (a, n, t = [], i) => {
            let r = t;
            if (typeof n == "string") r = r.concat(h(n).split(" ").map(o => `${a}:${o}`));else if (Array.isArray(n)) r = r.concat(n.reduce((o, c) => o.concat(`${a}:${c}`), []));else if (typeof n == "object" && typeof i == "string") {
              for (let o in n) if (n.hasOwnProperty(o) && o === i) {
                let c = n[o];
                if (c && typeof c == "string") {
                  let u = h(c);
                  r[i] ? r[i] = r[i].concat(u.split(" ").map(f => `${a}:${f}`)) : r[i] = u.split(" ").map(f => `${a}:${f}`);
                } else Array.isArray(c) && c.length > 0 && (r[i] = c.reduce((u, f) => u.concat(`${a}:${f}`), []));
              }
            }
            return r;
          },
          D = (a$1, n = g$1, t = null, i = null) => {
            var L;
            let r = n[a$1];
            if (!r || b(r)) return null;
            let o = (L = i == null ? void 0 : i[a$1]) != null ? L : l == null ? void 0 : l[a$1];
            if (o === null) return null;
            let c = a(o),
              u = Array.isArray(m.responsiveVariants) && m.responsiveVariants.length > 0 || m.responsiveVariants === !0,
              f = w == null ? void 0 : w[a$1],
              d = [];
            if (typeof c == "object" && u) for (let [E, Q] of Object.entries(c)) {
              let ne = r[Q];
              if (E === "initial") {
                f = Q;
                continue;
              }
              Array.isArray(m.responsiveVariants) && !m.responsiveVariants.includes(E) || (d = P(E, ne, d, t));
            }
            let $ = c != null && typeof c != "object" ? c : a(f),
              A = r[$ || "false"];
            return typeof d == "object" && typeof t == "string" && d[t] ? _(d, A) : d.length > 0 ? (d.push(A), t === "base" ? d.join(" ") : d) : A;
          },
          p = () => g$1 ? Object.keys(g$1).map(a => D(a, g$1)) : null,
          ee = (a, n) => {
            if (!g$1 || typeof g$1 != "object") return null;
            let t = new Array();
            for (let i in g$1) {
              let r = D(i, g$1, a, n),
                o = a === "base" && typeof r == "string" ? r : r && r[a];
              o && (t[t.length] = o);
            }
            return t;
          },
          H = {};
        for (let a in l) l[a] !== void 0 && (H[a] = l[a]);
        let I = (a, n) => {
            var i;
            let t = typeof (l == null ? void 0 : l[a]) == "object" ? {
              [a]: (i = l[a]) == null ? void 0 : i.initial
            } : {};
            return {
              ...w,
              ...H,
              ...t,
              ...n
            };
          },
          J = (a = [], n) => {
            let t = [];
            for (let {
              class: i,
              className: r,
              ...o
            } of a) {
              let c = !0;
              for (let [u, f] of Object.entries(o)) {
                let d = I(u, n)[u];
                if (Array.isArray(f)) {
                  if (!f.includes(d)) {
                    c = !1;
                    break;
                  }
                } else {
                  let $ = A => A == null || A === !1;
                  if ($(f) && $(d)) continue;
                  if (d !== f) {
                    c = !1;
                    break;
                  }
                }
              }
              c && (i && t.push(i), r && t.push(r));
            }
            return t;
          },
          te = a => {
            let n = J(h$1, a);
            if (!Array.isArray(n)) return n;
            let t = {};
            for (let i of n) if (typeof i == "string" && (t.base = M(t.base, i)(m)), typeof i == "object") for (let [r, o] of Object.entries(i)) t[r] = M(t[r], o)(m);
            return t;
          },
          ae = a => {
            if (C.length < 1) return null;
            let n = {};
            for (let {
              slots: t = [],
              class: i,
              className: r,
              ...o
            } of C) {
              if (!b(o)) {
                let c = !0;
                for (let u of Object.keys(o)) {
                  let f = I(u, a)[u];
                  if (f === void 0 || (Array.isArray(o[u]) ? !o[u].includes(f) : o[u] !== f)) {
                    c = !1;
                    break;
                  }
                }
                if (!c) continue;
              }
              for (let c of t) n[c] = n[c] || [], n[c].push([i, r]);
            }
            return n;
          };
        if (!b(O) || !S) {
          let a = {};
          if (typeof j == "object" && !b(j)) for (let n of Object.keys(j)) a[n] = t => {
            var i, r;
            return M(j[n], ee(n, t), ((i = te(t)) != null ? i : [])[n], ((r = ae(t)) != null ? r : [])[n], t == null ? void 0 : t.class, t == null ? void 0 : t.className)(m);
          };
          return a;
        }
        return M(k, p(), J(h$1), l == null ? void 0 : l.class, l == null ? void 0 : l.className)(m);
      },
      K = () => {
        if (!(!g$1 || typeof g$1 != "object")) return Object.keys(g$1);
      };
    return V.variantKeys = K(), V.extend = e, V.base = k, V.slots = j, V.variants = g$1, V.defaultVariants = w, V.compoundSlots = C, V.compoundVariants = h$1, V;
  },
  fe = s => (b, e) => ce(b, e ? g(s, e) : s);
export { M as cn, N as cnBase, fe as createTV, ie as defaultConfig, ce as tv, x as voidEmpty };