<label [attr.for]="controlName" class="ml-3 block text-sm font-medium leading-6 text-indigo-50/60">
  {{ label }}
</label>
<div class="relative mt-2 w-full max-w-sm rounded-md shadow-sm">
  <textarea
    *ngIf="type === 'textarea'"
    [formControlName]="controlName"
    [id]="controlName"
    [name]="controlName"
    [class]="classes"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [attr.aria-invalid]="hasError"
    [attr.aria-describedby]="errorLabeledBy"
    [autocomplete]="autocomplete"
    [maxlength]="maxLength"
    [rows]="rows"></textarea>

  <input
    *ngIf="type !== 'textarea'"
    [type]="type"
    [formControlName]="controlName"
    [id]="controlName"
    [name]="controlName"
    [class]="classes"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [attr.aria-invalid]="hasError"
    [attr.aria-describedby]="errorLabeledBy"
    [autocomplete]="autocomplete" />

  <div *ngIf="maxLength < maxLengthInfinite" class="mt-2 text-right text-xs text-indigo-50/40">
    {{ form?.get(controlName)?.value?.trim().length }}/{{ maxLength }}
  </div>

  <div
    *ngIf="hasError"
    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
    <svg class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
      <path
        fill-rule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
        clip-rule="evenodd" />
    </svg>
  </div>
</div>
<p *ngIf="hasError" class="mt-2 text-sm text-red-600" id="oldPassword-error">
  {{ errorMessage }}
</p>
