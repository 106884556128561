import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ButtonComponent } from './button/button.component';
import { ChipsInputComponent } from './chips-input/chips-input.component';
import { FooterComponent } from './footer/footer.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NewsletterSignupComponent } from './newsletter-signup/newsletter-signup.component';
import { ToastAlertComponent } from './toast-alert/toast-alert.component';

export const CORE_COMPONENTS = [];

@NgModule({
  declarations: [
    FooterComponent,
    NavigationComponent,
    ButtonComponent,
    LoadingIndicatorComponent,
    FormFieldComponent,
    MessageBoxComponent,
    NewsletterSignupComponent,
    ToastAlertComponent,
    ChipsInputComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule],
  exports: [
    FooterComponent,
    NavigationComponent,
    ButtonComponent,
    LoadingIndicatorComponent,
    FormFieldComponent,
    MessageBoxComponent,
    NewsletterSignupComponent,
    ToastAlertComponent,
    ChipsInputComponent,
  ],
})
export class CoreModule {}
