var l = e => typeof e == "boolean" ? `${e}` : e === 0 ? "0" : e,
  u = e => !e || typeof e != "object" || Object.keys(e).length === 0,
  x = (e, o) => JSON.stringify(e) === JSON.stringify(o),
  A = e => typeof e == "boolean";
function i(e, o) {
  e.forEach(function (r) {
    Array.isArray(r) ? i(r, o) : o.push(r);
  });
}
function y(e) {
  let o = [];
  return i(e, o), o;
}
var a = (...e) => y(e).filter(Boolean),
  p = (e, o) => {
    let r = {},
      c = Object.keys(e),
      f = Object.keys(o);
    for (let t of c) if (f.includes(t)) {
      let s = e[t],
        n = o[t];
      Array.isArray(s) || Array.isArray(n) ? r[t] = a(n, s) : typeof s == "object" && typeof n == "object" ? r[t] = p(s, n) : r[t] = n + " " + s;
    } else r[t] = e[t];
    for (let t of f) c.includes(t) || (r[t] = o[t]);
    return r;
  },
  g = e => !e || typeof e != "string" ? e : e.replace(/\s+/g, " ").trim();
export { l as a, u as b, x as c, A as d, y as e, a as f, p as g, g as h };