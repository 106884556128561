<!-- <div class="flex items-center justify-center space-x-6 py-4">
  <a routerLink="/">Purchase plans</a>
  <a routerLink="/sign-up">Sign up</a>
  <a routerLink="/sign-in">Sign in</a>
  <a *ngIf="isAdmin$ | async" routerLink="/admin">Admin</a>
</div> -->

<!-- <pre class="mt-4">{{ userService.user$ | async | json }}</pre> -->
<div
  class="sticky top-0 z-40 transition-all duration-500"
  [ngClass]="{
    'bg-neutral-950/90': (isScrolled$ | async) && !showMobileMenu,
    'bg-[#15151B]': showMobileMenu,
  }">
  <nav class="container mx-auto flex items-center justify-between gap-2 px-4 py-4 lg:px-6">
    <a routerLink="/" class="block">
      <img
        src="/assets/images/logo.svg"
        alt="Blackout"
        class="pointer-events-none hidden h-10 sm:block" />
      <img
        src="/assets/images/logo_short.svg"
        alt="Blackout"
        class="pointer-events-none h-10 sm:hidden" />
    </a>

    <div class="hidden flex-1 items-center justify-center gap-2 xl:flex">
      <a
        href="/#about"
        class="px-4 py-2 text-xs font-semibold uppercase leading-3 tracking-wider text-indigo-50/60"
        >About</a
      >
      <a
        href="/#features"
        class="px-4 py-2 text-xs font-semibold uppercase leading-3 tracking-wider text-indigo-50/60"
        >Features</a
      >
      <a
        href="/#tutorials"
        class="px-4 py-2 text-xs font-semibold uppercase leading-3 tracking-wider text-indigo-50/60"
        >Tutorials</a
      >
      <a
        href="/#pricing"
        class="px-4 py-2 text-xs font-semibold uppercase leading-3 tracking-wider text-indigo-50/60"
        >Pricing</a
      >
      <a
        href="/#support"
        class="px-4 py-2 text-xs font-semibold uppercase leading-3 tracking-wider text-indigo-50/60"
        >Support</a
      >
      <a
        [routerLink]="'/navigator'"
        class="glow-effect px-4 py-2 text-xs font-semibold uppercase leading-3 tracking-wider text-yellow-500">
        Navigator
      </a>

      <a
        *ngIf="isAdminOrEditor$ | async"
        routerLink="/admin"
        class="rounded-xl bg-indigo-50/10 px-4 py-2 text-xs font-semibold uppercase leading-3 tracking-wider text-indigo-50/80"
        >Admin</a
      >
    </div>

    <div class="flex flex-shrink-0 items-center">
      <div
        class="hidden items-center gap-2 text-sm leading-none tracking-tight text-indigo-50/80 lg:flex">
        <app-button
          *ngIf="isLoggedIn"
          routerLink="/account"
          [buttonStyle]="'secondary'"
          class="text-blue-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            class="mr-2 h-4 w-4 fill-current">
            <path
              d="M2.666 14.667a5.333 5.333 0 0 1 10.667 0h-1.334a4 4 0 0 0-8 0H2.666Zm5.333-6c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4Zm0-1.334A2.666 2.666 0 1 0 8 2a2.666 2.666 0 1 0 0 5.333Z" />
          </svg>

          My Account
        </app-button>

        <app-button *ngIf="isLoggedIn" [buttonStyle]="'secondary'" (click)="signOut()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="h-4 w-4 fill-current">
            <path
              fill-rule="evenodd"
              d="M3 4.25A2.25 2.25 0 015.25 2h5.5A2.25 2.25 0 0113 4.25v2a.75.75 0 01-1.5 0v-2a.75.75 0 00-.75-.75h-5.5a.75.75 0 00-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75v-2a.75.75 0 011.5 0v2A2.25 2.25 0 0110.75 18h-5.5A2.25 2.25 0 013 15.75V4.25z"
              clip-rule="evenodd" />
            <path
              fill-rule="evenodd"
              d="M6 10a.75.75 0 01.75-.75h9.546l-1.048-.943a.75.75 0 111.004-1.114l2.5 2.25a.75.75 0 010 1.114l-2.5 2.25a.75.75 0 11-1.004-1.114l1.048-.943H6.75A.75.75 0 016 10z"
              clip-rule="evenodd" />
          </svg>
        </app-button>

        <app-button *ngIf="!isLoggedIn" routerLink="/sign-up">Sign up</app-button>

        <app-button *ngIf="!isLoggedIn" routerLink="/sign-in" [buttonStyle]="'secondary'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" class="h-4 w-4 fill-current">
            <path
              d="M2.667 14.667a5.333 5.333 0 0 1 10.666 0H12a4 4 0 0 0-8 0H2.667Zm5.333-6c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4Zm0-1.334A2.666 2.666 0 1 0 8 2a2.666 2.666 0 1 0 0 5.333Z" />
          </svg>

          Sign in
        </app-button>

        <app-button
          [buttonStyle]="'primary'"
          [href]="'https://apps.apple.com/us/app/blackout-lighting-console/id1414562959'"
          [target]="'_blank'"
          >Download App</app-button
        >
      </div>

      <!-- mobile nav -->
      <div class="ml-2 flex items-center gap-2 text-sm leading-none tracking-tight xl:hidden">
        <app-button
          [routerLink]="isLoggedIn ? '/account' : '/sign-in'"
          class="lg:hidden"
          [class]="isLoggedIn ? 'text-blue-500' : ''"
          [buttonStyle]="'secondary'"
          [buttonSize]="'md'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            class="mr-2 h-4 w-4 fill-current">
            <path
              d="M2.667 14.667a5.333 5.333 0 0 1 10.666 0H12a4 4 0 0 0-8 0H2.667Zm5.333-6c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4Zm0-1.334A2.666 2.666 0 1 0 8 2a2.666 2.666 0 1 0 0 5.333Z" />
          </svg>
          {{ isLoggedIn ? 'My Account' : 'Sign In' }}
        </app-button>

        <app-button
          *ngIf="isLoggedIn"
          class="lg:hidden"
          [buttonStyle]="'secondary'"
          (click)="signOut()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="h-4 w-4 fill-current">
            <path
              fill-rule="evenodd"
              d="M3 4.25A2.25 2.25 0 015.25 2h5.5A2.25 2.25 0 0113 4.25v2a.75.75 0 01-1.5 0v-2a.75.75 0 00-.75-.75h-5.5a.75.75 0 00-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75v-2a.75.75 0 011.5 0v2A2.25 2.25 0 0110.75 18h-5.5A2.25 2.25 0 013 15.75V4.25z"
              clip-rule="evenodd" />
            <path
              fill-rule="evenodd"
              d="M6 10a.75.75 0 01.75-.75h9.546l-1.048-.943a.75.75 0 111.004-1.114l2.5 2.25a.75.75 0 010 1.114l-2.5 2.25a.75.75 0 11-1.004-1.114l1.048-.943H6.75A.75.75 0 016 10z"
              clip-rule="evenodd" />
          </svg>
        </app-button>

        <app-button
          (click)="showMobileMenu = !showMobileMenu"
          [buttonStyle]="'secondary'"
          [buttonSize]="'md'">
          &nbsp;
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 fill-current" viewBox="0 0 20 20">
            <path
              fill-rule="evenodd"
              d="M18.333 2.5v1.667H1.666V2.5h16.667Zm0 13.333V17.5H1.666v-1.667h16.667Zm0-6.666H1.666v1.666h16.667V9.167Z"
              clip-rule="evenodd" />
          </svg>
          &nbsp;
        </app-button>
      </div>
    </div>
  </nav>

  <div
    *ngIf="showMobileMenu"
    class="divide-y-1 absolute inset-x-0 flex flex-col divide-indigo-50/20 bg-[#15151B] px-4 pb-6 shadow-lg shadow-fuchsia-500/50 xl:hidden">
    <a
      href="/#about"
      class="px-4 py-4 font-semibold uppercase leading-3 tracking-wider text-indigo-50/60"
      (click)="closeMobileMenu()"
      >About</a
    >
    <a
      href="/#features"
      class="px-4 py-4 font-semibold uppercase leading-3 tracking-wider text-indigo-50/60"
      (click)="closeMobileMenu()"
      >Features</a
    >
    <a
      href="/#tutorials"
      class="px-4 py-4 font-semibold uppercase leading-3 tracking-wider text-indigo-50/60"
      (click)="closeMobileMenu()"
      >Tutorials</a
    >
    <a
      href="/#pricing"
      class="px-4 py-4 font-semibold uppercase leading-3 tracking-wider text-indigo-50/60"
      (click)="closeMobileMenu()"
      >Pricing</a
    >
    <a
      href="/#support"
      class="px-4 py-4 font-semibold uppercase leading-3 tracking-wider text-indigo-50/60"
      (click)="closeMobileMenu()"
      >Support</a
    >
    <a
      [routerLink]="'/navigator'"
      class="glow-effect px-4 py-4 font-semibold uppercase leading-3 tracking-wider text-yellow-400"
      (click)="closeMobileMenu()">
      Navigator
    </a>

    <a
      *ngIf="isAdminOrEditor$ | async"
      routerLink="/admin"
      class="rounded-xl bg-indigo-50/10 px-4 py-4 font-semibold uppercase leading-3 tracking-wider text-indigo-50/80"
      >Admin</a
    >
  </div>
</div>
