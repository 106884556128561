import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';

import { tv } from 'tailwind-variants';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  standalone: false,
})
export class FormFieldComponent {
  readonly maxLengthInfinite = Number.MAX_SAFE_INTEGER;

  @Input() class = '';
  @Input() label = '';
  @Input() controlName = '';
  @Input() type = 'text';
  @Input() form?: FormGroup;
  @Input() placeholder = '';
  @Input() required = true;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() error = '';
  @Input() autocomplete = '';
  @Input() maxLength = this.maxLengthInfinite;
  @Input() rows = 3;

  private variants = tv({
    base: 'block w-full rounded-xl border-0 bg-indigo-50/5 py-3 pl-6 pr-10  ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 shadow-xl',
    variants: {
      state: {
        normal:
          'text-indigo-50/80 ring-transparent placeholder:text-indigo-50/60 focus:ring-blue-500',
        error: 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500',
      },
      readonly: {
        true: 'bg-transparent ring-indigo-50/10 text-indigo-50/60 cursor-not-allowed text-indigo-50/60',
      },
    },
    compoundVariants: [],
    defaultVariants: {
      state: 'normal',
    },
  });

  get classes(): string {
    return this.variants({
      state: this.hasError ? 'error' : 'normal',
      readonly: this.readonly,
      // overrides
      class: this.class,
    });
  }

  get hasError(): boolean {
    const error =
      this.form?.get(this.controlName)?.invalid &&
      (this.form?.get(this.controlName)?.dirty || this.form?.get(this.controlName)?.touched);
    return !!error;
  }

  get errorLabeledBy(): string {
    return `${this.controlName}-error`;
  }

  get errorMessage(): string {
    const error = this.form?.get(this.controlName)?.errors;
    if (!error) {
      return '';
    }

    console.log(error);
    if (error['required']) {
      return `${this.label} is required`;
    }
    if (error['minlength']) {
      return `${this.label} must be at least ${error['minlength'].requiredLength} characters`;
    }
    if (error['maxlength']) {
      return `${this.label} must be no more than ${error['maxlength'].requiredLength} characters`;
    }
    if (error['email']) {
      return `${this.label} must be a valid email address`;
    }
    if (error['passwordMismatch']) {
      return `Passwords must match`;
    }
    return `${this.label} is invalid`;
  }
}
