import { Component } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-newsletter-signup',
  templateUrl: './newsletter-signup.component.html',
  styleUrls: ['./newsletter-signup.component.scss'],
  standalone: false,
})
export class NewsletterSignupComponent {
  email: string | null = null;
  errorMessage: string | null = null;
  message: string | null = null;
  isSubmitting = false;

  marketingList: boolean = false;
  announcementOption: 'important' | 'build_and_important' | null = 'important'; // New variable to handle either-or selection

  async onSubmit(event: Event) {
    event.preventDefault();
    if (!this.email) {
      this.errorMessage = 'Please enter an email address.';
      return;
    }

    const formData = {
      email: this.email,
      marketingList: this.marketingList,
      importantAnnouncementsList: this.announcementOption === 'important',
      buildAnnouncementsList: this.announcementOption === 'build_and_important',
    };

    this.isSubmitting = true;
    this.errorMessage = null;

    const res = await fetch(`${environment.api}/newsletter/sign-up`, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .catch(err => {
        console.error(err);
        return null;
      });

    if (res?.status === 'ok') {
      this.email = null;
      this.marketingList = false; // Reset checkbox state
      this.announcementOption = null; // Reset radio button state
      this.errorMessage = null;
      this.message = 'Thank you for signing up!';
      return;
    }

    this.errorMessage =
      res?.errors?.email?._errors?.join(', ') ?? 'Something went wrong. Please try again.';
  }
}
