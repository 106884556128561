import { Component, Input } from '@angular/core';

import { tv } from 'tailwind-variants';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: false,
})
export class ButtonComponent {
  @Input() class = '';
  @Input() text = '';
  @Input() href: string | undefined;
  @Input() target = '_self';
  @Input() buttonStyle: 'primary' | 'secondary' | 'tertiary' | 'destructive' = 'tertiary';
  @Input() buttonSize: 'sm' | 'md' | 'lg' = 'md';
  @Input() disabled = false;
  @Input() loading = false;

  private variants = tv({
    base: 'flex items-center transition-colors duration-300',
    variants: {
      style: {
        primary:
          'bg-gradient-to-r from-sky-500 to-blue-700 text-indigo-50 hover:from-sky-600 hover:to-blue-800',
        secondary: 'bg-indigo-50/10  text-indigo-50/80 hover:bg-indigo-50/20',
        tertiary: 'text-indigo-50/80 hover:text-indigo-50',
        destructive: 'text-red-500 hover:text-red-600 bg-red-50/10 hover:bg-red-50/20',
      },
      size: {
        sm: 'text-sm px-3 py-1.5',
        md: 'px-4 py-3',
        lg: 'text-lg px-6 py-4',
      },
      disabled: {
        true: 'opacity-50 cursor-not-allowed',
      },
    },
    compoundVariants: [
      {
        style: ['primary', 'secondary', 'destructive'],
        class: 'rounded-xl',
      },
      {
        style: ['primary', 'secondary', 'destructive'],
        size: ['sm'],
        class: 'rounded-lg',
      },
    ],
    defaultVariants: {
      style: 'tertiary',
      size: 'md',
    },
  });

  get classes(): string {
    return this.variants({
      style: this.buttonStyle,
      size: this.buttonSize,
      disabled: this.disabled,
      // overrides
      class: this.class,
    });
  }

  get elementType(): string {
    return this.href ? 'a' : 'button';
  }
}
