<form (submit)="onSubmit($event)" class="">
  <app-message-box
    *ngIf="errorMessage"
    [type]="'error'"
    [title]="'Error!'"
    [message]="errorMessage"></app-message-box>

  <app-message-box
    *ngIf="message"
    [type]="'success'"
    [title]="'Success!'"
    [message]="message"></app-message-box>

  <div class="space-y-4 pb-4">
    <div class="flex items-center space-x-3">
      <div class="flex items-center">
        <input
          id="marketing-list"
          aria-describedby="comments-description"
          name="marketing-list"
          type="checkbox"
          class="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
          [(ngModel)]="marketingList" />
      </div>
      <div class="text-sm leading-6">
        <label for="marketing-list" class="font-medium text-indigo-50"
          >News and Special Offers</label
        >
      </div>
    </div>
    <div class="flex items-center space-x-3">
      <div class="flex items-center">
        <input
          id="important-list"
          aria-describedby="comments-description"
          name="announcement-options"
          type="radio"
          class="h-4 w-4 rounded-full border-gray-300 text-blue-500 focus:ring-blue-500"
          [(ngModel)]="announcementOption"
          [value]="'important'" />
      </div>
      <div class="text-sm leading-6">
        <label for="important-list" class="font-medium text-indigo-50">
          Important Updates Only
        </label>
      </div>
    </div>
    <div class="flex items-center space-x-3">
      <div class="flex items-center">
        <input
          id="build-announcements-list"
          aria-describedby="comments-description"
          name="announcement-options"
          type="radio"
          class="h-4 w-4 rounded-full border-gray-300 text-blue-500 focus:ring-blue-500"
          [(ngModel)]="announcementOption"
          [value]="'build_and_important'" />
      </div>
      <div class="text-sm leading-6">
        <label for="build-announcements-list" class="font-medium text-indigo-50">
          Build Announcements and Important Updates
        </label>
      </div>
    </div>
  </div>

  <div class="flex items-stretch space-x-4">
    <div class="relative flex flex-col">
      <input
        type="email"
        name="email"
        [(ngModel)]="email"
        class="w-full max-w-md rounded-xl border border-[#F1F1FF0A] bg-indigo-50/5 px-4 text-indigo-50 shadow-xl outline-none placeholder:text-indigo-50/80"
        placeholder="Enter your email" />
    </div>

    <app-button class="flex-shrink-0" [buttonStyle]="'primary'" [loading]="isSubmitting">
      Join Mailing List
    </app-button>
  </div>
</form>
